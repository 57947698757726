<template>
	<div>
		<microsoft-teams-settings />
	</div>
</template>
<script>
	import MicrosoftTeamsSettings from "@/components/integrations/microsoft_teams/MicrosoftTeamsSettings.vue";
	export default {
		name: 'MicrosoftTeams',
		meta: {
			title: 'Alert Cascade - Microsoft Teams'
		},
		components: { MicrosoftTeamsSettings },
	}
</script>
