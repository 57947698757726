<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Microsoft Teams
		</h1>
		<q-tabs
			inline-label
			active-color="primary"
			indicator-color="primary"
		>
			<q-route-tab
				v-for="(tab, index) in tabs_data"
				:key="index"
				:to="{ name: tab.name }"
				:label="tab.label"
			/>
		</q-tabs>
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	name: 'MicrosoftTeamsSettings',
	data() {
		return {
			tabs_data: [
				{
					'label':'Webhooks List',
					'name': 'Teams Webhooks List',
				},
				{
					'label':'Webhooks Reports',
					'name': 'Teams Webhooks Reports',
				},
			],
		}
	},
	watch:{
		$route(to, from){
			if (to.name === 'Microsoft Teams'){
				this.$router.push({ name: "Teams Webhooks List" })
			}
		}
	},
	created(){
		if (this.$route.name !== "Teams Webhooks List"){
			this.$router.push({ name: "Teams Webhooks List" })
		}
	}
}
</script>
